export type AnnouncementVariant = {
  mainText: string | JSX.Element;
  linkText: string;
  link: string;
  backgroundColor?: string;
  textColor?: string;
  image?: string;
  imageAlt?: string;
  startDate: Date;
  endDate: Date;
  locales: string[];
};

export enum AnnouncementVariants {
  RichardHammond = "RichardHammond",
  RichardHammond2 = "RichardHammond2",
}
