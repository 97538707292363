import { P1 } from "components/atoms/Paragraph";
import { Arrow } from "components/atoms/svgs/Arrow";
import Link from "next/link";
import { FC } from "react";
import { AnnouncementBarContainer, Img, LeftSection, LinkContainer, RightSection } from "./styles";
import { useScrollThreshold } from "@hooks";

export type AnnouncementVariant = {
  mainText: string | JSX.Element;
  linkText: string;
  link: string;
  backgroundColor?: string;
  textColor?: string;
  image?: string;
  imageAlt?: string;
  startDate: Date;
  endDate: Date;
  locales: string[];
};

export const AnnouncementBar: FC<{ variant?: AnnouncementVariant }> = ({ variant }) => {
  const isScrolled = useScrollThreshold();

  return (
    <Link href={variant.link} target="_blank" rel="noopener noreferrer">
      <AnnouncementBarContainer isScrolled={isScrolled} backgroundColor={variant.backgroundColor}>
        <LeftSection>
          {typeof variant.mainText === "string" ? (
            <P1
              pt="0"
              pb="0"
              color={variant.textColor || "#FFF"}
              fontSize="1rem"
              lineHeight="1.5rem"
            >
              {variant.mainText}
            </P1>
          ) : (
            variant.mainText
          )}
        </LeftSection>
        <RightSection>
          <LinkContainer>
            <P1
              pt="0"
              pr="0.5rem"
              color={variant.textColor || "#FFF"}
              bold={true}
              fontSize="1rem"
              lineHeight="1.5rem"
            >
              {variant.linkText}
            </P1>
            <Arrow />
          </LinkContainer>
          {variant.image && (
            <Img src={variant.image} alt={variant.imageAlt} height={56} width={64} />
          )}
        </RightSection>
      </AnnouncementBarContainer>
    </Link>
  );
};
