import { P1 } from "components/atoms/Paragraph";
import { AnnouncementVariants } from "./types";

export const announcementVariants = {
  [AnnouncementVariants.RichardHammond]: {
    mainText: (
      <P1 pt="0" pb="0" color="#FFF" fontSize="1rem" lineHeight="1.5rem">
        MetLife UK x YuLife presents{" "}
        <span style={{ fontWeight: "700" }}>Resilience & Recovery with Richard Hammond</span> 4th
        December 2024 at 4pm GMT
      </P1>
    ),
    linkText: "Sign up to the live webinar",
    link: "https://events.yulife.com/richard-hammond/?utm_campaign=2024-12%20YuLife%20x%20MetLife%20Presents%20Richard%20Hammond&utm_source=website-banner&utm_medium=yulife",
    image: "/static/img/announcement-bar-images/RichardHammond.png",
    imageAlt: "Richard Hammond",
    startDate: new Date("2024-11-20"),
    endDate: new Date("2024-12-04"),
    locales: ["uk"],
  },
};
