import { BetterButton } from "components/atoms/BetterButton";
import { useState, useEffect, useCallback, FC } from "react";
import styled from "styled-components";
import { Logo } from "../atoms/NewLogo";
import { MenuItem } from "../atoms/MenuItem";
import { useLocale, useScrollThreshold, YuLifeLocale } from "hooks";
import { Link } from "@components/atoms";
import { FlagIcon } from "components/atoms/FlagIcon";
import { MegaMenuProducts } from "components/organisms/MegaMenu/MegaMenuProducts";
import { MegaMenuFeatures } from "components/organisms/MegaMenu/MegaMenuFeatures";
import { MegaMenuResources } from "components/organisms/MegaMenu/MegaMenuResources";
import { MegaMenuLogIn } from "components/organisms/MegaMenu/MegaMenuLogIn";
import { MegaMenuAbout } from "components/organisms/MegaMenu/MegaMenuAbout";
import { color, debounce } from "@utils";
import { HamburgerMenu } from "components/atoms/HamburgerMenu";
import MobileNav from "components/organisms/Navigation/components/Navbar/components/MobileNav/MobileNav";
import { NavbarType } from "components/organisms/Navigation/components/Navbar/Navbar.types";
import ukNavLinks from "components/organisms/Navigation/components/Navbar/ukNavLinks";
import usNavLinks from "components/organisms/Navigation/components/Navbar/usNavLinks";
import jpNavLinks from "components/organisms/Navigation/components/Navbar/jpNavLinks";
import zaNavLinks from "components/organisms/Navigation/components/Navbar/zaNavLinks";
import { mobile, shrinkNav } from "components/templates/Breakpoints";
import { MegaMenuLocale } from "components/organisms/MegaMenu/MegaMenuLocale";
import { ExternalRoutes, StaticRoutes } from "@routes";
import { LatestCaseStudy } from "utils/server";
import { MegaMenuAdviser } from "components/organisms/MegaMenu/MegaMenuAdviser";
import { DaiIchiYuLifeDoubleLogo } from "components/atoms/DaiIchiYuLifeDoubleLogo";

const US_NAVBAR_OPTIONS = ["Our Solutions", "Features", "Resources", "About"];
const JP_NAVBAR_OPTIONS = ["アプリについて", "最新情報"];
const NAVBAR_OPTIONS = ["Products", "Features", "Resources", "About"];

interface MenuBarProps {
  isMinimised?: boolean;
  navbarType: NavbarType;
  topToolbar: boolean;
  $hasDaiIchiLogo: boolean;
  announcementBar: boolean;
  topBarHeight: string;
}

const getLinks = (locale: YuLifeLocale) => {
  switch (locale) {
    case "us":
      return usNavLinks;
    case "za":
      return zaNavLinks;
    case "jp":
      return jpNavLinks;
    case "uk":
    default:
      return ukNavLinks;
  }
};

const getFlagImg = (locale: YuLifeLocale) => {
  switch (locale) {
    case "us":
      return <FlagIcon src="/static/icons/flags/US.svg" locale="United States" />;
    case "za":
      return <FlagIcon src="/static/icons/flags/ZA.svg" locale="South Africa" />;
    case "jp":
      return <FlagIcon src="/static/icons/flags/JP.svg" locale="Japan" />;
    case "uk":
    default:
      return <FlagIcon src="/static/icons/flags/GB-UKM.svg" locale="United Kingdom" />;
  }
};

export const NavBar: FC<{
  navbarType?: NavbarType;
  hideLinks?: boolean;
  hideRegionSelector?: boolean;
  latestCaseStudy?: LatestCaseStudy;
  topToolbar: boolean;
  hasDaiIchiLogo: boolean;
  triggerDaiIchiRedirect: () => void;
  announcementBar: boolean;
}> = ({
  navbarType,
  hideLinks = false,
  hideRegionSelector = false,
  latestCaseStudy,
  topToolbar,
  hasDaiIchiLogo,
  triggerDaiIchiRedirect,
  announcementBar,
}) => {
  const isScrolled = useScrollThreshold();
  const { locale } = useLocale();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  let links = getLinks(locale);

  const [currentlyClicked, setCurrentlyClicked] = useState<string>();
  const [isHovering, setIsHovering] = useState(false);
  const menuSegments = getNavBarOptions(locale);
  const buttonText = getButtonText(locale);
  const minimisedButtonLength = locale === "us" ? "14.2rem" : "6.5rem";
  const logInText = locale === "jp" ? "ログイン" : "Log in";
  const daiIchiYuLifeDoubleLogo = locale === "jp" && hasDaiIchiLogo;

  function getNavBarOptions(locale: YuLifeLocale) {
    switch (locale) {
      case "us":
        return US_NAVBAR_OPTIONS;
      case "jp":
        return JP_NAVBAR_OPTIONS;
      default:
        return NAVBAR_OPTIONS;
    }
  }

  function getButtonText(locale: YuLifeLocale) {
    switch (locale) {
      case "us":
        return "Request a demo";
      case "jp":
        return "お問い合わせ";
      default:
        return "Get a quote";
    }
  }

  let topBarHeight: string;

  switch (true) {
    case announcementBar:
      topBarHeight = "5.75rem";
      break;
    case topToolbar:
      topBarHeight = "3.5rem";
      break;
    default:
      topBarHeight = "0";
      break;
  }

  useEffect(() => {
    // if screen is resized while fullscreen mobile nav is open, close it
    window.addEventListener(
      "resize",
      debounce(() => {
        // calculating the base font size allows us to use "rem"-like widths
        const baseFontSize = Number(
          window.getComputedStyle(document.body).getPropertyValue("font-size").match(/\d+/)[0],
        );
        // if window becomes greater than 75rem, close the mobile navigation
        if (window.innerWidth > 70 * baseFontSize) {
          setOpenMobileNav(false);
        }
      }, 1000),
    );
  }, []);

  const handleMenuClick = useCallback((item: string) => {
    setCurrentlyClicked(item);
  }, []);

  const getMegaMenu = (currentlyClicked: string, hideLinks = false) => {
    if (!isHovering) {
      return null;
    }

    switch (currentlyClicked) {
      case "Products":
        return <MegaMenuProducts />;
      case "Our Solutions":
        return <MegaMenuProducts />;
      case "Features":
        return <MegaMenuFeatures />;
      case "アプリについて":
        return <MegaMenuFeatures />;
      case "Resources":
        return <MegaMenuResources latestCaseStudy={latestCaseStudy} />;
      case "最新情報":
        return <MegaMenuResources latestCaseStudy={latestCaseStudy} />;
      case "About":
        return <MegaMenuAbout />;
      case "YuLifeについて":
        return <MegaMenuAbout />;
      case "Adviser":
        return <MegaMenuAdviser />;
      case "Log in":
        return <MegaMenuLogIn />;
      case "Locale":
        return <MegaMenuLocale hideLinks={hideLinks} />;
      case "":
      default:
        return null;
    }
  };

  return openMobileNav ? (
    <MobileNav
      setOpenMobileNav={setOpenMobileNav}
      links={links}
      hideLinks={hideLinks}
      hideRegionSelector={hideRegionSelector}
    />
  ) : (
    <Relative>
      <MegaMenuWrapper
        isScrolled={isScrolled}
        isHovering={isHovering}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        {getMegaMenu(currentlyClicked, hideLinks)}
      </MegaMenuWrapper>
      <MenuBar
        navbarType={navbarType}
        isMinimised={isScrolled}
        topToolbar={topToolbar}
        $hasDaiIchiLogo={hasDaiIchiLogo}
        announcementBar={announcementBar}
        topBarHeight={topBarHeight}
      >
        {hideLinks ? (
          <>
            {daiIchiYuLifeDoubleLogo ? (
              <Link passHref href="/">
                <DaiIchiYuLifeDoubleLogo
                  isMinimized={isScrolled}
                  color={
                    navbarType === NavbarType.primaryInverted && !isScrolled
                      ? color.white
                      : color.P100
                  }
                />
              </Link>
            ) : (
              <Logo
                isMinimized={isScrolled}
                color={
                  navbarType === NavbarType.primaryInverted && !isScrolled
                    ? color.white
                    : color.P100
                }
              />
            )}
          </>
        ) : (
          <>
            {daiIchiYuLifeDoubleLogo ? (
              <Link passHref href="/">
                <DaiIchiYuLifeDoubleLogo
                  isMinimized={isScrolled}
                  color={
                    navbarType === NavbarType.primaryInverted && !isScrolled
                      ? color.white
                      : color.P100
                  }
                />
              </Link>
            ) : (
              <Link passHref href="/" legacyBehavior>
                <LogoWrap>
                  <Logo
                    isMinimized={isScrolled}
                    color={
                      navbarType === NavbarType.primaryInverted && !isScrolled
                        ? color.white
                        : color.P100
                    }
                  />
                </LogoWrap>
              </Link>
            )}
          </>
        )}
        {hideLinks ? null : (
          <Wrap>
            <Margin $hasDaiIchiLogo={!!hasDaiIchiLogo} />
            {locale === "jp" && (
              <MenuItem
                isScrolled={isScrolled}
                navbarType={navbarType}
                hasArrow={false}
                isActive={isHovering && currentlyClicked === "Products"}
                onMouseEnter={() => {
                  handleMenuClick("Products");
                  setIsHovering(true);
                }}
                onMouseLeave={() => {
                  setIsHovering(false);
                }}
                onClick={triggerDaiIchiRedirect}
              >
                商品一覧
              </MenuItem>
            )}
            {menuSegments.map((item) => (
              <MenuItem
                isScrolled={isScrolled}
                navbarType={navbarType}
                key={item}
                isActive={isHovering && item === currentlyClicked}
                onMouseEnter={() => {
                  handleMenuClick(item);
                  setIsHovering(true);
                }}
                onMouseLeave={() => {
                  setIsHovering(false);
                }}
              >
                {item}
              </MenuItem>
            ))}
            {locale === "uk" && (
              <Link href={StaticRoutes.adviser}>
                <MenuItem
                  isScrolled={isScrolled}
                  navbarType={navbarType}
                  isActive={isHovering && currentlyClicked === "Adviser"}
                  onMouseEnter={() => {
                    handleMenuClick("Adviser");
                    setIsHovering(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                  }}
                >
                  Adviser
                </MenuItem>
              </Link>
            )}
            {locale === "jp" && (
              <Link href={StaticRoutes.aboutUs}>
                <MenuItem
                  isScrolled={isScrolled}
                  navbarType={navbarType}
                  hasArrow={false}
                  isActive={isHovering && currentlyClicked === "About"}
                  onMouseEnter={() => {
                    handleMenuClick("About");
                    setIsHovering(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                  }}
                >
                  YuLifeについて
                </MenuItem>
              </Link>
            )}
          </Wrap>
        )}
        <Wrap>
          {hideLinks ? null : (
            <MenuItem
              isScrolled={isScrolled}
              navbarType={navbarType}
              isActive={isHovering && currentlyClicked === "Locale"}
              onMouseEnter={() => {
                handleMenuClick("Locale");
                setIsHovering(true);
              }}
              onMouseLeave={() => {
                setIsHovering(false);
              }}
            >
              <FlagWrap>{getFlagImg(locale)}</FlagWrap>
            </MenuItem>
          )}
          {hideLinks ? null : (
            <LinkWrap>
              <MenuItem
                isScrolled={isScrolled}
                navbarType={navbarType}
                isActive={isHovering && currentlyClicked === "Log in"}
                onMouseEnter={() => {
                  handleMenuClick("Log in");
                  setIsHovering(true);
                }}
                onMouseLeave={() => {
                  setIsHovering(false);
                }}
              >
                {logInText}
              </MenuItem>
            </LinkWrap>
          )}
          {hideLinks ? null : (
            <Link passHref href={StaticRoutes.getAQuote} legacyBehavior>
              <ButtonWrap>
                <BetterButton
                  variant="menu"
                  isMinimised={isScrolled}
                  text={buttonText}
                  minimisedWidth={minimisedButtonLength}
                />
              </ButtonWrap>
            </Link>
          )}
        </Wrap>
        {hideLinks && hideRegionSelector ? null : (
          <LI onClick={() => setOpenMobileNav(true)}>
            <HamburgerMenu
              color={
                navbarType === NavbarType.primaryInverted && !isScrolled ? color.white : color.H100
              }
              isMinimized={isScrolled}
            />
          </LI>
        )}
      </MenuBar>
    </Relative>
  );
};

const Relative = styled.div`
  position: relative;
`;

const MegaMenuWrapper = styled.div<{
  isHovering?: boolean;
  isScrolled?: boolean;
}>`
  position: absolute;
  top: 5rem;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  padding: 1rem 0 0;
  ${(p) => (!p.isHovering ? "pointer-events: none" : "")}
  ${(p) => (p.isScrolled ? "translate: 0 -1.75rem" : "")}
`;

const MenuBar = styled.div<MenuBarProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: red;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  box-sizing: border-box;
  background: #ffffff;
  transition: 200ms ease-out;
  background-color: ${(p) => (p.isMinimised ? "#FFFFFF" : "transparent")};
  border-bottom: ${(p) => (p.isMinimised ? "1px solid #e3e3e1" : "none")};
  transform: ${(p) =>
    (p.isMinimised && p.topToolbar) || (p.isMinimised && p.announcementBar)
      ? "translateY(-3.5rem)"
      : "translateY(0)"};
  transition: 200ms ease-out;

  height: ${(p) => (p.isMinimised ? "5.5rem" : "9rem")};
  padding: 0 2.5rem;

  @media only screen and (max-width: 84.5rem) {
    padding: ${(p) => (p.$hasDaiIchiLogo ? "0 1.5rem" : "0 2.5rem")};
  }

  ${mobile} {
    height: ${(p) => (p.isMinimised ? "3rem" : "6.5rem")};
    padding: ${(p) => (p.isMinimised ? "0.5rem 1rem 0" : "0 1rem")};
  }

  @media only screen and (max-width: 29.5rem) {
    transform: ${(p) =>
      (p.isMinimised && p.announcementBar) || (p.isMinimised && p.topToolbar)
        ? `translateY(-${p.topBarHeight})`
        : "translateY(0)"};
  }
`;

const Margin = styled.div<{
  $hasDaiIchiLogo: boolean;
}>`
  margin-left: ${(p) => (p.$hasDaiIchiLogo ? "5rem" : "15.625rem")};

  ${shrinkNav} {
    margin-left: min(15.625rem, 5%);
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 70rem) {
    display: none;
  }
`;

const LogoWrap = styled.div`
  width: 1rem;
  cursor: pointer;
`;

const FlagWrap = styled.span`
  padding-top: 0.125rem;
  padding-right: 0.125rem;
`;

const ButtonWrap = styled.div`
  margin-left: 2rem;

  @media only screen and (max-width: 86.75rem) {
    button {
      padding: 1rem;
    }
  }

  ${shrinkNav} {
    display: none;
  }
`;

const LI = styled.li<{ color?: string }>`
  position: relative;
  font-weight: bold;
  letter-spacing: 0.075rem;
  color: ${(p) => p.color || color.white};
  display: none;
  padding: 2rem 0 1.25rem 2rem;
  cursor: pointer;

  @media only screen and (max-width: 70rem) {
    display: inline-block;
    cursor: pointer;
  }

  ${mobile} {
    padding: 0;
  }
`;

const LinkWrap = styled.div`
  margin-bottom: 0.2rem;
`;
