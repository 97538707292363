import { color } from "@styles";
import { tablet } from "@yu-life/yulife-components-static";
import styled from "styled-components";
import Image from "next/image";

export const AnnouncementBarContainer = styled.div<{
  isScrolled?: boolean;
  backgroundColor?: string;
}>`
  height: 3.5rem;
  width: 100%;
  display: flex;
  padding: 1rem 2.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 0rem;
  background: ${(p) => (p.backgroundColor ? p.backgroundColor : color.S3100)};
  transform: ${(p) => (p.isScrolled ? "translateY(-3.5rem)" : "translateY(0)")};
  transition: 200ms ease-out;

  @media only screen and (max-width: 45.5rem) {
    padding: 1rem;
  }

  @media only screen and (max-width: 29.5rem) {
    height: 5.75rem;
    transform: ${(p) => (p.isScrolled ? "translateY(-5.75rem)" : "translateY(0)")};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;

  ${tablet} {
    margin-top: 0rem;
  }

  svg {
    transition: 200ms;
  }

  &:hover {
    svg {
      transform: translateX(0.5rem);
      transition: 200ms;
    }
  }
`;

export const Img = styled(Image)`
  @media only screen and (max-width: 54.5rem) {
    display: none;
  }
`;

export const LeftSection = styled.div``;

export const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;

  p {
    padding-left: 1rem;
  }

  @media only screen and (max-width: 43rem) {
    p {
      display: none;
    }
  }
`;
